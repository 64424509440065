import { useState } from 'react'
import cx from 'classnames'
import * as R from 'ramda'
import { CUSTOM } from '.'

import s from './styles.module.css'
import useLag from 'hooks/use-lag'

const SectionOption = ({
  className,
  value,
  label,
  labelDisplay,
  tags,
  imageUrl,
  disableHoverActions,
  setIsFocused,
  setValue,
  handleCustomSelect,
  onOptionSelect,
  onBlur,
  setFocusedOption,
  renderLabel,
  valueRegex,
  renderActionButtonsOnHover,
  sectionName,
  isMobile,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const showActionButtons = useLag(isHovering, 500)
  return (
    <div
      key={R.propIs(Function, 'getKey', value) ? value.getKey() : value}
      className={cx(s.sectionOption, className)}
      onClick={() => {
        if (setIsFocused) setIsFocused(false)

        /**
         * Ensures that the search input is not cleared if the
         * user clicks on an option when the section option is
         * being rendered in a hover context
         * (i.e. the add workout modal)
         */
        if (!renderActionButtonsOnHover) {
          setValue('')
        }

        if (value === CUSTOM) {
          handleCustomSelect()
        } else {
          onOptionSelect(value, label)
        }
        if (isMobile) {
          setIsHovering(true)
        }
        onBlur && onBlur()
      }}
      onMouseEnter={() => {
        setFocusedOption(value)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
        setFocusedOption(null)
      }}
      onMouseOver={() => {
        if (!disableHoverActions) setIsHovering(true)
      }}>
      {showActionButtons && <div className={s.sectionOptionBlock} />}
      <div className={s.sectionOptionText}>
        <div className={s.textWrapper}>
          {labelDisplay || renderLabel({ label, valueRegex })}
          {tags && tags.length > 0 && (
            <> [{renderLabel({ label: R.join(', ', tags), valueRegex })}]</>
          )}
        </div>
        {imageUrl && (
          <div>
            <img src={imageUrl} className={s.image} />
          </div>
        )}
        {showActionButtons &&
          renderActionButtonsOnHover({ id: value.id, sectionName, value })}
      </div>
    </div>
  )
}

export default SectionOption
