import * as breakpoints from 'constants/breakpoints'
import { useWindowSizeCondition } from './use-window-size-condition'

export const useIsUnderNewTabletLandscapeWidth = () => {
  return useWindowSizeCondition(
    ({ width }) => width < breakpoints.NEW_TABLET_LANDSCAPE
  )
}

export const useIsUnderNewTabletPortraitWidth = () => {
  return useWindowSizeCondition(
    ({ width }) => width < breakpoints.NEW_TABLET_PORTRAIT
  )
}

export const useIsUnderTabletWidth = () => {
  return useWindowSizeCondition(({ width }) => width < breakpoints.TABLET_WIDTH)
}
