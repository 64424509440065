import RadioSelect from 'components/shared/radio-select'
import * as R from 'ramda'

const TIMEZONE_OPTIONS = [
  { value: -10, label: 'Hawaii time' },
  { value: -9, label: 'Alaska time' },
  { value: -8, label: 'Pacific time' },
  { value: -7, label: 'Mountain time' },
  { value: -6, label: 'Central time' },
  { value: -5, label: 'Eastern time' },
  { value: -4, label: 'Atlantic time' },
  { value: -3, label: 'Newfoundland time' },
]

export const findMatchingTzLabel = offset =>
  R.propOr(
    null,
    'label',
    R.find(R.propEq('value', parseInt(offset)), TIMEZONE_OPTIONS)
  )

const UtcOffsetPicker = ({ value, onChange }) => {
  return (
    <RadioSelect options={TIMEZONE_OPTIONS} value={value} onChange={onChange} />
  )
}

export default UtcOffsetPicker
