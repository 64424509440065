import * as R from 'ramda'
import { useState, useEffect } from 'react'
import cx from 'classnames'

import usePrevious from 'hooks/use-previous'
import RadioWithLabel from 'components/shared/radio-with-label'
import s from './styles.module.css'

const RadioSelect = ({
  options,
  onChange,
  value: initialValue,
  className,
  optionClassName,
  labelClassName,
  labelWrapperClassName,
  radioClassName,
  radioInputClassName,
}) => {
  const [value, setValue] = useState(R.isNil(initialValue) ? '' : initialValue)
  const previousValue = usePrevious(value)

  useEffect(() => {
    if (
      typeof previousValue !== 'undefined' &&
      previousValue !== value &&
      onChange
    ) {
      onChange(value)
    }
  }, [onChange, value, previousValue])

  useEffect(() => {
    setValue(R.isNil(initialValue) ? '' : initialValue)
  }, [initialValue])

  return (
    <ol className={className}>
      {R.map(({ value: optionValue, label, subText }) => {
        return (
          <li key={optionValue} className={cx(s.option, optionClassName)}>
            <RadioWithLabel
              onClick={() => setValue(optionValue)}
              checked={optionValue === value}
              label={label}
              subText={subText}
              className={radioClassName}
              inputClassName={radioInputClassName}
              labelWrapperClassName={cx(s.labelWrapper, labelWrapperClassName)}
              labelClassName={cx(s.label, labelClassName)}
            />
          </li>
        )
      }, options)}
    </ol>
  )
}

export default RadioSelect
