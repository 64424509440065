import * as R from 'ramda'
import cx from 'classnames'
import { useRef, useState, useEffect } from 'react'

import usePrevious from 'hooks/use-previous'
import downArrow from 'images/down-arrow-orange.svg'
import s from './styles.module.css'

const Select = ({
  value,
  options,
  onChange,
  className,
  inputClassName,
  optionClassName,
  placeholderClassName,
  placeholder,
  label = '',
  isV2 = false,
  includeBlank = false,
  crossfadePlaceholder = false,
  disabled = false,
}) => {
  const inputEl = useRef(null)
  const isBlank = !value && !R.find(R.propEq('value', value), options)
  const [activePlaceholder, setActivePlaceholder] = useState(placeholder)
  const previousPlaceholder = usePrevious(activePlaceholder)

  useEffect(() => {
    setTimeout(() => setActivePlaceholder(placeholder), 100)
  }, [placeholder])

  return (
    <div className={cx(s.container, className)}>
      {isV2 && placeholder && isBlank && (
        <>
          <div
            className={cx(
              s.placeholder,
              {
                [s.placeholderFadeIn]: activePlaceholder === placeholder,
                [s.placeholderHidden]: activePlaceholder !== placeholder,
              },
              placeholderClassName
            )}>
            {activePlaceholder}
          </div>
          {crossfadePlaceholder && previousPlaceholder && (
            <div
              className={cx(
                s.placeholder,
                {
                  [s.placeholderFadeOut]: activePlaceholder === placeholder,
                  [s.placeholderVisible]: activePlaceholder !== placeholder,
                },
                placeholderClassName
              )}>
              {previousPlaceholder}
            </div>
          )}
        </>
      )}
      <select
        ref={inputEl}
        className={cx({ [s.select]: true, [s.selectV2]: isV2 }, inputClassName)}
        disabled={disabled}
        value={value}
        onChange={onChange}
        aria-label={label}>
        {R.map(
          option => (
            <option
              className={cx(s.option, optionClassName)}
              key={option.value}
              value={option.value}>
              {option.label}
            </option>
          ),
          includeBlank ? [{ value: '', label: '' }, ...options] : options
        )}
      </select>
      {isV2 && <img src={downArrow} alt="Down arrow" className={s.arrowIcon} />}
    </div>
  )
}

export default Select
