import { useState, useCallback, useEffect, useMemo } from 'react'
import * as R from 'ramda'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fuse from 'fuse.js'

import { useIsMobileLayout } from 'components/dashboard/clients/hooks'
import useLag from 'hooks/use-lag'
import { escapeRegexp } from 'utilities/strings'
import SectionOption from './section-option'
import s from './styles.module.css'
import Image from 'next/image'

export const CUSTOM = 'CUSTOM'
export const CUSTOM_OPTION = { label: 'Add custom', value: CUSTOM }

const renderLabel = ({ label, valueRegex, _data }) => {
  if (!valueRegex || !label || !R.is(String, label)) return label

  const match = R.match(valueRegex, label)
  if (!R.isNil(match?.index)) {
    const length = match[0].length
    const idx = match.index
    const prefix = R.take(idx, label)
    const suffix = R.drop(idx + length, label)

    return R.reject(R.isNil, [
      prefix ? <span key="PREFIX">{prefix}</span> : null,
      <strong className={s.match} key="MAIN">
        {R.slice(idx, idx + length, label)}
      </strong>,
      suffix ? <span key="SUFFIX">{suffix}</span> : null,
    ])
  }

  return label
}

const TextSelect = ({
  neverHideResults = false,
  disabled = false,
  options,
  onOptionSelect,
  onCustomSelect,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  placeholderIcon,
  inputClassName,
  optionsClassName,
  optionClassName,
  sectionNameClassName,
  sectionOptionClassName,
  wrapperClassName,
  selectedValue = '',
  selectedLabel,
  maxLength,
  disableSubmitOnEnter = false,
  eraseCustomAfterSubmit = true,
  allowCustom = true,
  showCustomOption = true,
  autoFocus = false,
  optionsTop = false,
  forceClear = false,
  isV2 = false,
  showAddNew = false,
  disableFilter = false,
  enableFuzzySearch = true,
  sectionSchema = [],
  renderActionButtonsOnHover,
}) => {
  const isMobile = useIsMobileLayout()
  const getSelectedLabel = () => {
    if (selectedLabel) return selectedLabel

    return R.pipe(
      R.find(R.propEq('value', selectedValue)),
      R.prop('label')
    )(options)
  }

  const [isFocused, setIsFocused] = useState(false)
  const [focusedOption, setFocusedOption] = useState(null)
  const [value, setValue] = useState(getSelectedLabel())
  const laggingValue = useLag(value, 300)
  let valueRegex = null
  if (value && value.length > 1) {
    try {
      valueRegex = new RegExp(value, 'i')
    } catch (error) {
      console.error(`regex error: `, error)
    }
  }
  const [filteredOptions, setFilteredOptions] = useState(options)
  useEffect(() => {
    if (forceClear && value) {
      setValue('')
      onChange({ value: '', closestMatch: null })
    }
  }, [forceClear, value, onChange])

  useEffect(() => {
    if (!value) setValue(getSelectedLabel())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedValue, selectedLabel])

  const fuzzySearcher = useMemo(() => {
    if (!enableFuzzySearch) return null

    const lastOption = R.last(options)
    const useSearchIndex = !R.isNil(lastOption?.searchIndex)
    const useTags = !R.isNil(lastOption?.tags)
    const mainField = useSearchIndex ? 'searchIndex' : 'label'

    return new Fuse(options, {
      ignoreLocation: true,
      keys: useTags
        ? [
            {
              name: mainField,
              weight: 0.7,
            },
            {
              name: 'tags',
              weight: 0.3,
            },
          ]
        : [mainField],
    })
  }, [options, enableFuzzySearch])

  const disableFilterOptions = useMemo(() => {
    return R.filter(R.prop('disableFilter'), options)
  }, [options])

  const getFilteredOptions = query => {
    if (disableFilter) {
      return allowCustom && showCustomOption
        ? R.prepend(CUSTOM_OPTION, options)
        : options
    }

    return R.pipe(
      R.ifElse(
        () => enableFuzzySearch && fuzzySearcher && query,
        () => {
          const results = fuzzySearcher.search(query)

          return R.uniqBy(R.prop('value'), [
            ...disableFilterOptions,
            ...R.map(R.prop('item'), results),
          ])
        },
        R.filter(({ label, searchIndex, disableFilter: disableItemFilter }) => {
          if (disableItemFilter) return true

          return R.test(
            new RegExp(escapeRegexp(query), 'i'),
            searchIndex || label
          )
        })
      ),
      R.ifElse(
        () => allowCustom && showCustomOption,
        R.prepend(CUSTOM_OPTION),
        R.identity
      )
    )(options)
  }

  useEffect(() => {
    const filteredOptions = getFilteredOptions(laggingValue)

    setFilteredOptions(
      filteredOptions.length === 1 && filteredOptions[0].value === selectedValue
        ? options
        : filteredOptions
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, laggingValue])

  const handleFocus = () => {
    onFocus && onFocus()
    setIsFocused(true)
  }

  const handleBlur = useCallback(() => {
    if (!focusedOption) {
      setTimeout(() => {
        onBlur && onBlur()
        setIsFocused(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedOption])

  const handleChange = ev => {
    const val = ev.target.value
    setValue(val)

    const filteredOptions = getFilteredOptions(val)
    const closestMatch = filteredOptions[0]
    onChange && onChange({ value: val, closestMatch: closestMatch?.value })
  }

  const onKeyDown = ev => {
    if (ev.key === 'Enter' && !disableSubmitOnEnter) {
      const filteredOptions = getFilteredOptions(value)
      const closestMatch = filteredOptions[0]

      if (closestMatch && closestMatch.value !== CUSTOM) {
        ev.stopPropagation()
        onOptionSelect(closestMatch.value, closestMatch.label)
      } else if (allowCustom) {
        ev.stopPropagation()
        onCustomSelect(value)
        setIsFocused(false)
        eraseCustomAfterSubmit && setValue('')
        onBlur && onBlur()
      }
    }

    return true
  }

  const addCustom = () => {
    onCustomSelect(value)
    setIsFocused(false)
  }

  const handleCustomSelect = useCallback(() => {
    onCustomSelect(value)
  }, [value, onCustomSelect])

  const sectionedOptions = useMemo(() => {
    return sectionSchema.map(({ sectionName, predicate }) => ({
      sectionName,
      sectionOptions: filteredOptions.filter(predicate),
    }))
  }, [filteredOptions, sectionSchema])

  return (
    <div className={cx(s.wrapper, wrapperClassName)}>
      <div className={s.inputWrapper}>
        <input
          disabled={disabled}
          autoFocus={autoFocus}
          className={cx(
            { [s.input]: !isV2, [s.inputV2]: isV2 },
            inputClassName
          )}
          type="text"
          value={value || ''}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
        />
        {placeholderIcon && (
          <div className={s.placeholderIcon}>
            <FontAwesomeIcon icon={placeholderIcon} />
          </div>
        )}
        {!!value && allowCustom && showAddNew && (
          <button className={s.addNewButton} onClick={addCustom}>
            Add new
          </button>
        )}
      </div>
      {sectionedOptions.length > 0 && (
        <div className={s.sections}>
          {sectionedOptions.map(({ sectionName, sectionOptions }) => (
            <div key={sectionName} className={s.section}>
              <div className={cx(s.sectionName, sectionNameClassName)}>
                {sectionName}
              </div>
              <div className={s.sectionOptions}>
                {sectionOptions.map(option => (
                  <SectionOption
                    {...option}
                    key={
                      R.propIs(Function, 'getKey', option?.value)
                        ? option?.value.getKey()
                        : option?.value
                    }
                    className={sectionOptionClassName}
                    setIsFocued={setIsFocused}
                    setValue={setValue}
                    handleCustomSelect={handleCustomSelect}
                    onOptionSelect={onOptionSelect}
                    onBlur={onBlur}
                    setFocusedOption={setFocusedOption}
                    renderLabel={renderLabel}
                    valueRegex={valueRegex}
                    renderActionButtonsOnHover={renderActionButtonsOnHover}
                    sectionName={sectionName}
                    isMobile={isMobile}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {sectionedOptions.length === 0 && (
        <ol
          className={cx(s.options, optionsClassName, {
            [s.optionsHidden]:
              (!neverHideResults && !isFocused) || !filteredOptions.length,
            [s.optionsTop]: optionsTop,
          })}>
          {R.map(
            ({ value, label, labelDisplay, tags, imageUrl }) => (
              <li
                key={
                  R.propIs(Function, 'getKey', value) ? value.getKey() : value
                }
                className={cx(
                  s.option,
                  { [s.span3]: !!!imageUrl },
                  optionClassName
                )}
                onClick={() => {
                  setIsFocused(false)
                  setValue('')
                  if (value === CUSTOM) {
                    handleCustomSelect()
                  } else {
                    onOptionSelect(value, label)
                  }
                  onBlur && onBlur()
                }}
                onMouseEnter={() => setFocusedOption(value)}
                onMouseLeave={() => setFocusedOption(null)}>
                <div className={s.optionContent}>
                  <div className={s.textWrapper}>
                    <h2>
                      {labelDisplay || renderLabel({ label, valueRegex })}
                    </h2>
                    {tags && tags.length > 0 && (
                      <div className={s.tagWrapper}>
                        {R.map(
                          (tag, i) => (
                            <label key={`${tag}-${i}`} className={s.tag}>
                              {tag}
                            </label>
                          ),
                          R.uniq(R.map(R.toLower, tags))
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {imageUrl && (
                  <div className={s.imageWrapper}>
                    <Image
                      alt={label}
                      src={imageUrl}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                )}
              </li>
            ),
            filteredOptions
          )}
        </ol>
      )}
    </div>
  )
}

export default TextSelect
