import Radio from 'components/shared/radio'
import SelectWithLabel from 'components/shared/select-with-label'

const RadioWithLabel = ({
  checked,
  label,
  onClick,
  labelWrapperClassName,
  labelClassName,
}) => {
  return (
    <SelectWithLabel
      label={label}
      onClick={onClick}
      labelWrapperClassName={labelWrapperClassName}
      labelClassName={labelClassName}>
      <Radio checked={checked} />
    </SelectWithLabel>
  )
}

export default RadioWithLabel
