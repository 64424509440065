import { useState, useEffect, useRef } from 'react'

const isBrowser = typeof window !== 'undefined'

const getSize = () => {
  return {
    width: isBrowser ? window.innerWidth : undefined,
    height: isBrowser ? window.innerHeight : undefined,
  }
}

export const useWindowSizeCondition = <T>(
  condition: (size: { width?: number; height?: number }) => T,
  // optional value to initialize with when page is rendered on the server
  serverValue?: T
) => {
  const [value, setValue] = useState<T>(
    typeof serverValue === 'undefined' ? condition(getSize()) : serverValue
  )
  const isBrowserInit = useRef<boolean>(false)

  useEffect(() => {
    const handleResize = () => setValue(condition(getSize()))

    if (!isBrowserInit.current) {
      handleResize()
      isBrowserInit.current = true
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [condition])

  return value
}
