import { useEffect, useRef, useState } from 'react'

// returns latest value after lag duration has elapsed without changes
const useLag = (value, lag) => {
  const [laggingValue, setLaggingValue] = useState(value)
  const timeoutId = useRef()

  useEffect(() => {
    const clearCurrentTimeout = () => {
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }

    clearCurrentTimeout()

    timeoutId.current = setTimeout(() => setLaggingValue(value), lag)

    return () => clearCurrentTimeout()
  }, [value, lag])

  return laggingValue
}

export default useLag
