import breakpoints from 'styles/breakpoints.module.css'

const parsePx = value => parseInt(value.replace(/px/, ''))

export const DESKTOP_LG_WIDTH = parsePx(breakpoints.DESKTOP_LG_WIDTH)
export const DESKTOP_MED_WIDTH = parsePx(breakpoints.DESKTOP_MED_WIDTH)
export const DESKTOP_WIDTH = parsePx(breakpoints.DESKTOP_WIDTH)
export const MOBILE_WIDTH_MAX = parsePx(breakpoints.MOBILE_WIDTH_MAX)
export const TABLET_WIDTH = parsePx(breakpoints.TABLET_WIDTH)
export const MOBILE_WIDTH = parsePx(breakpoints.MOBILE_WIDTH)

export const NEW_MOBILE_MAX = parsePx(breakpoints.NEW_MOBILE_MAX)
export const NEW_TABLET_PORTRAIT = parsePx(breakpoints.NEW_TABLET_PORTRAIT)
export const NEW_TABLET_PORTRAIT_MAX = parsePx(
  breakpoints.NEW_TABLET_PORTRAIT_MAX
)
export const NEW_TABLET_LANDSCAPE = parsePx(breakpoints.NEW_TABLET_LANDSCAPE)
export const NEW_TABLET_LANDSCAPE_MAX = parsePx(
  breakpoints.NEW_TABLET_LANDSCAPE_MAX
)
export const NEW_DESKTOP_SMALL = parsePx(breakpoints.NEW_DESKTOP_SMALL)
