import { useEffect, useContext, useMemo, useCallback } from 'react'

import PusherContext from 'contexts/pusher-context'

const usePusher = (channelName, eventName, callback, onReconnected) => {
  const pusherContext = useContext(PusherContext)
  const { subscribe, unsubscribe, debug, justReconnected } = pusherContext

  const channel = useMemo(() => {
    if (!channelName) return null

    return subscribe(channelName)
  }, [subscribe, channelName])

  const applyCallback = useCallback(
    (...args) => {
      if (callback) callback(...args)
      if (debug) console.log(channelName, eventName, ...args)
    },
    [callback, channelName, debug, eventName]
  )

  useEffect(() => {
    return () => {
      if (channelName) unsubscribe(channelName)
    }
  }, [channelName, unsubscribe])

  useEffect(() => {
    if (channel) channel.bind(eventName, applyCallback)

    return () => {
      if (channel) channel.unbind(eventName, applyCallback)
    }
  }, [channel, eventName, applyCallback])

  useEffect(() => {
    if (justReconnected && onReconnected) {
      setTimeout(onReconnected, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [justReconnected])

  return pusherContext
}

export default usePusher
