import cx from 'classnames'

import s from './styles.module.css'

const SelectWithLabel = ({
  children,
  label,
  subText,
  onClick,
  labelWrapperClassName,
  labelClassName,
}) => {
  return (
    <div className={s.container} onClick={onClick}>
      {children}
      <div className={cx(s.labelWrapper, labelWrapperClassName)}>
        <span className={labelClassName}>{label}</span>
        {subText && <div className={s.subText}>{subText}</div>}
      </div>
    </div>
  )
}

export default SelectWithLabel
