import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import * as R from 'ramda'
import axios from 'axios'
import { useState, useEffect } from 'react'
import uuid from 'uuid/v1'
import cx from 'classnames'
import { uploadFileFromWidget } from 'utilities/file'
import { sleep } from 'utilities/promises'
import { getFile, getFileUploadUrl } from './queries.graphql'
import { createFile as createFileMutation } from './mutations.graphql'
import s from './styles.module.css'
import { Hidden } from 'components/design-system'
import dynamic from 'next/dynamic'
import usePusher from 'hooks/use-pusher'

// import MuxVideo from 'components/shared/mux-video'
const MuxVideo = dynamic(() => import('components/shared/mux-video'))

const DEFAULT_ACCEPTABLE_ATTACHMENT_FILE_TYPES = ['image/*', 'video/*']

const FilePreview = ({
  isImage,
  isVideo,
  file,
  isVideoProcessing,
  imageClassName,
  isSaved,
  onVideoError,
}) => (
  <>
    {isImage && (
      <img src={file.url} alt="File" className={cx(s.image, imageClassName)} />
    )}
    {isVideo && !file.isMuxVideo && (
      <>
        {!isVideoProcessing && (
          <video
            className={cx(s.image, imageClassName)}
            src={file.url}
            onError={onVideoError}
            controls
            preload="true"
          />
        )}
        {isVideoProcessing && (
          <div className={s.processingNote}>
            Your video is being processed. Please wait 5 seconds...
          </div>
        )}
      </>
    )}
    {isVideo && file.isMuxVideo && (
      <>
        {file.muxVideoIsReady != 1 && isSaved && (
          <div className={s.processingText}>
            Processing... Refresh to see changes
          </div>
        )}
        {file.muxVideoIsReady != 1 && !isSaved && (
          <div className={s.processingText}>
            Video uploaded. Remember to save.
          </div>
        )}
        {file.muxVideoIsReady == 1 && (
          <div className={cx(s.image, imageClassName)}>
            <MuxVideo muxPlaybackId={file.muxPlaybackId} />
          </div>
        )}
      </>
    )}
    {!isImage && !isVideo && !!file && (
      <div className={s.image}>
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.filename}
        </a>
      </div>
    )}
  </>
)

const FileInput = ({
  buttonText = 'Upload File',
  onChange,
  onClick,
  fileId: initialFileId,
  error,
  className,
  filePreviewPosition = 'bottom',
  imageClassName,
  transformation,
  Icon,
  isSaved = true,
  isMuxVideo = false,
  acceptableFileTypes = DEFAULT_ACCEPTABLE_ATTACHMENT_FILE_TYPES,
  eagerLoadTransformations = [],
  uploadWidgetOptions = {},
  onExternalPreview,
  onError,
  onDoneProcessing,
}) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [justUploaded, setJustUploaded] = useState(false)
  const [isVideoProcessing, setIsVideoProcessing] = useState(false)
  const [id, setId] = useState(null)
  const [fileId, setFileId] = useState(initialFileId)
  const [createFile] = useMutation(createFileMutation)
  const apolloClient = useApolloClient()
  const filePayload = useQuery(getFile, {
    variables: { id: fileId, transformation },
    skip: !fileId,
  })
  const file = R.path(['data', 'file'], filePayload)
  const isImage = !!file && R.test(/^image\//, file.mimetype)
  const isVideo = !!file && R.test(/^video\//, file.mimetype)

  useEffect(() => {
    setId(uuid())
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    setFileId(initialFileId || null)
  }, [initialFileId])

  useEffect(() => {
    if (isInitialized) onChange(fileId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId])

  useEffect(() => {
    onExternalPreview?.(file)
  }, [file?.muxPlaybackId, file?.muxVideoIsReady])

  const handleChange = async ({ target: { files } }) => {
    onError && onError(null)
    setUploading(true)
    const attachment = files[0]
    const mimetype = attachment.type

    const {
      data: { fileUploadUrl },
    } = await apolloClient.query({
      query: getFileUploadUrl,
      variables: { mimetype, forS3: isMuxVideo },
      fetchPolicy: 'no-cache',
    })

    const uploadRes = await axios.put(fileUploadUrl.url, attachment, {
      headers: { 'Content-Type': mimetype },
      onUploadProgress: progressEvent => {
        setUploadProgress(
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        )
      },
    })

    if (uploadRes.status !== 200) {
      setUploading(false)
      setUploadProgress(0)
      throw new Error('Attachment upload failed')
    }

    const {
      data: {
        createFile: { id },
      },
    } = await createFile({
      variables: {
        attachmentData: {
          filename: attachment.name,
          encoding: attachment.encoding,
          key: fileUploadUrl.key,
          mimetype,
          isMuxVideo,
        },
        transformation,
      },
    })

    setFileId(id)
    setUploading(false)
    setUploadProgress(0)
  }
  const handleMuxEventReceived = async data => {
    await filePayload.refetch()
    const hasError = data.type === 'video.asset.errored'
    if (hasError) {
      onError?.(data.data?.errors?.messages?.[0] ?? 'Unknown error occurred')
    }
    onDoneProcessing?.(!hasError)
  }

  usePusher(
    isMuxVideo && fileId && `mux-file-${fileId}`,
    'mux-event',
    handleMuxEventReceived
  )

  const handleClick = async ev => {
    onClick && onClick(ev)

    const file = await uploadFileFromWidget({
      apolloClient,
      uploadWidgetOptions,
      eagerLoadTransformations,
    })
    setFileId(file.id)
    setJustUploaded(true)
    setUploading(false)
  }

  const onVideoError = async () => {
    if (justUploaded) {
      setIsVideoProcessing(true)
      await sleep(5000)
      setIsVideoProcessing(false)
      setJustUploaded(false)
    }
  }

  return (
    <div className={cx(s.container, className)}>
      <Hidden visible={filePreviewPosition === 'top'}>
        <FilePreview
          isImage={isImage}
          isVideo={isVideo}
          file={file}
          isVideoProcessing={isVideoProcessing}
          imageClassName={imageClassName}
          isSaved={isSaved}
          onVideoError={onVideoError}
        />
      </Hidden>
      {/* upload large mux videos directly to S3 */}
      {isMuxVideo && (
        <>
          <label
            className={cx(s.fileInputButton, {
              [s.fileInputButtonDisabled]: uploading,
            })}
            onClick={onClick}
            htmlFor={id}>
            {Icon && <Icon className={s.icon} />}
            {uploading ? `${uploadProgress}%` : buttonText}
          </label>
          <input
            id={id}
            className={s.fileInput}
            type="file"
            onChange={handleChange}
            accept={R.join(',', acceptableFileTypes)}
          />
        </>
      )}
      {!isMuxVideo && !file && (
        <button
          onClick={handleClick}
          type="button"
          className={cx(s.fileInputButton, {
            [s.fileInputButtonDisabled]: uploading,
          })}>
          {buttonText}
        </button>
      )}
      {!isMuxVideo && !!file && (
        <button className={s.deleteButton} onClick={() => setFileId(null)}>
          Remove file
        </button>
      )}
      {error && <div className={s.error}>{error}</div>}
      <Hidden visible={filePreviewPosition === 'bottom'}>
        <FilePreview
          isImage={isImage}
          isVideo={isVideo}
          file={file}
          isVideoProcessing={isVideoProcessing}
          imageClassName={imageClassName}
          isSaved={isSaved}
          onVideoError={onVideoError}
        />
      </Hidden>
    </div>
  )
}

export default FileInput
