import cx from 'classnames'

import circlePurple from 'images/circle-purple.svg'
import s from './styles.module.css'

const Radio = ({ checked, className, inputClassName }) => {
  return (
    <div className={cx(s.container, className)}>
      <input type="radio" checked={checked} className={s.radioInput} readOnly />
      <div className={cx(s.radio, inputClassName)}>
        {checked && (
          <img src={circlePurple} alt="Radio" className={s.radioIcon} />
        )}
      </div>
    </div>
  )
}

export default Radio
